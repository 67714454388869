.tp-protfolio-area {
    background: #2a2a2a;
    position: relative;
    padding-bottom: 160px;
}
.tp-protfolio-area.section-padding{
    padding-bottom: 160px;
}

.tp-protfolio-text h2 {
    font-size: 40px;
    color: #fff;
}

.tp-protfolio-text span {
    font-size: 18px;
    color: #fff;
    display: block;
    margin-bottom: 20px;
    font-weight: 300;
}

.tp-protfolio-text a {
    display: inline-block;
    font-size: 18px;
    color: #fff;
    padding: 10px 50px;
    background: rgba(255, 255, 255, .35);
    position: relative;
}
.tp-protfolio-text a:hover,
.tp-protfolio-text a:focus{
    color: #fff;
}
.tp-protfolio-text a:after{
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background: #fff;
    content: "";
}

.tp-protfolio-text a:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    z-index: 9;
    content: '';
    transition: all .3s;
}

.tp-protfolio-text a:hover:before{
    left: 0;
    opacity: .3;
}

.tp-protfolio-single,
.tp-blog-img {
    position: relative;
    margin-bottom: 1px;
    overflow: hidden;
}


.tp-protfolio-text {
    padding: 40px;
    position: absolute;
    left: 0;
    bottom:30px;
}

@media(max-width:1400px){
    .tp-protfolio-text {
        padding: 15px;
        bottom:15px;
    }
    .tp-protfolio-text h2{
        font-size: 30px;
    }
}
@media(max-width:991px){
    .tp-protfolio-area.section-padding{
        padding-bottom: 100px;
    }
}

.tp-protfolio-right {
    margin-top: -200px;
}

.tp-protfolio-item .col {
    padding: 1px;
}

.protfolio-btn a {
    font-size: 40px;
    color: #fff;
    font-weight: 700;

}

.protfolio-btn {
    text-align: right;
    margin-top: -45px;
}

@media(max-width: 991px) {
    .tp-protfolio-single, .tp-blog-img {
        margin-bottom: 30px;
    }
}

@media(max-width: 767px) {
    .tp-protfolio-area .section-title {
        margin-bottom: 40px;
    }

}


.tp-protfolio-area .tp-protfolio-single{
    margin-bottom: 30px;
}


.tp-protfolio-area .tp-protfolio-img{
    position: relative;
}

.tp-protfolio-area .tp-protfolio-img:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: rgba(32, 32, 32, .5);
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
}

.tp-protfolio-area .tp-protfolio-single:hover .tp-protfolio-img:before{
    opacity: 1;
    visibility: visible;
}

.tp-protfolio-area .tp-protfolio-text {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    left: -30px;
}

.tp-protfolio-area .tp-protfolio-single:hover .tp-protfolio-text{
    opacity: 1;
    visibility: visible;
    left: 0;
}
